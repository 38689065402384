import Swiper from 'swiper';
import {
	Pagination,
	Keyboard,
	Mousewheel,
	Navigation,
	Thumbs,
	EffectFade,
	Autoplay,
	Grid,
} from 'swiper/modules';
/**
 * @param swiperInit
 */

const sleep = (t) => new Promise((r) => setTimeout(r, t));

export function swiperInit() {
	var defaultSettingSwiper = {
		preventInteractionOnTransition: true,
		observer: true,
		observeParents: true,
		rewind: true,
		speed: 1205,
		loop: false,
		autoplay: {
			delay: 4000,
			pauseOnMouseEnter: true,
		},
		lazy: {
			loadPrevNext: true,
		},
	};


	

	var swiperGrid = new Swiper(".home-services-swiper .swiper", {
		modules: [Navigation, Pagination,Autoplay,Grid],
		autoplay: {
			delay: 3000,
			pauseOnMouseEnter: true,
		},
		lazy: {
			loadPrevNext: true,
		},
		observer: true,
		spaceBetween: 30,
		observeParents: true,
		breakpoints: {
			200: {
				slidesPerView: 1,
				spaceBetween: 15,
			},
			768: {
				slidesPerView: 2,
				spaceBetween: 20,
			},
			1024: {
				slidesPerView: 2,
				grid: {
					rows: 2,
					fill: "row",
				},
			},
		},

		navigation: {
			nextEl: ".home-services-swiper .next",
			prevEl: ".home-services-swiper .prev",
		},
		pagination: {
			el: ".home-services-swiper .swiper-pagination",
			clickable: true,
		},
	});
	var primarySwiper = new Swiper(".primary-banner .swiper", {
		modules: [Navigation, Pagination,Autoplay],
		defaultSettingSwiper,
		slidesPerView: 1,
		lazy: {
			loadPrevNext: true,
		},
		navigation: {
			nextEl: ".primary-banner  .next",
			prevEl: ".primary-banner  .prev",
		},
		pagination: {
			el: ".primary-banner  .swiper-pagination",
			type: "bullets",
			clickable: true,
		},
	});

	$(".init-swiper .swiper").each(function (index) {
		var $this = $(this);
		$this.addClass("swiper-init-" + index);
		$this
			.parents(".init-swiper")
			.find(".prev")
			.addClass("prev-nav-" + index);
		$this
			.parents(".init-swiper")
			.find(".next")
			.addClass("next-nav-" + index);
		$this
			.parents(".init-swiper")
			.find(".swiper-pagination")
			.addClass("pagination-inst-" + index);

		var swiper = new Swiper(".swiper-init-" + index, {
			defaultSettingSwiper,
			modules: [Navigation, Pagination,Autoplay],
			slidesPerView: "auto",
			navigation: {
				nextEl: ".next-nav-" + index,
				prevEl: ".prev-nav-" + index,
			},
			pagination: {
				el: ".pagination-inst-" + index,
				type: "bullets",
				clickable: true,
				dynamicBullets: true,
				dynamicMainBullets: 1,
			},
		});
	});
}