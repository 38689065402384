export function basicTask() {
	const backTop = document.querySelector(".back-to-top");
	const counterItem = document.querySelectorAll(".counter .number-counter");
	const setBackground = document.querySelectorAll("[setBackground]");
	const menuToggle = document.querySelector(".site-menu-toggle");
	const closeMenu = document.querySelector(".close-menu");
	const searchToggle = document.querySelector(".search-toggle");
	const bodyWrap = document.querySelector("body");
	const menuMobile = document.querySelector(".mobile-nav-wrap");
	const searchWrap = document.querySelector(".search-overlay");
	const hamburger = document.querySelector(".hamburger");
	const breadcrumb = document.querySelector(".global-breadcrumb");

	//Basic Toggle Function
	menuToggle.addEventListener("click", () => {
		menuMobile.classList.toggle("is-open");
		hamburger.classList.toggle("is-active");
		bodyWrap.classList.toggle("overlay-bg");
	});
	closeMenu.addEventListener("click", () => {
		menuMobile.classList.remove("is-open");
		hamburger.classList.remove("is-active");
		bodyWrap.classList.remove("overlay-bg");
	});

	// Search Toggle
	searchToggle.addEventListener("click", () => {
		searchWrap.classList.toggle("is-open");
		bodyWrap.classList.toggle("overlay-bg");
	});
	// Breadcrumb Title
	if (breadcrumb !== null) {
		if (bodyWrap.classList.contains("recruitment-detail-page")) {
			let anchorElements = breadcrumb.querySelectorAll(":scope a");
			let anchorBreadrumb =
				breadcrumb.querySelector(":scope .breadcrumb");
			let title = document.querySelector(
				".recruit-detail  .zone-title"
			).innerText;
			let newNode = document.createElement("h2");
			newNode.textContent = title;
			anchorBreadrumb.after(newNode);
		} else {
			let anchorElements = breadcrumb.querySelectorAll(":scope a");
			let anchorBreadrumb =
				breadcrumb.querySelector(":scope .breadcrumb");
			let secondAnchor = anchorElements[1];
			let title = secondAnchor.innerText;
			let newNode = document.createElement("h2");
			newNode.textContent = title;
			anchorBreadrumb.after(newNode);
		}
	}
	//back to top
	backTop.addEventListener("click", (event) => {
		event.preventDefault();
		$("html, body").animate({ scrollTop: 0 }, "300");
	});
	//Tab function
	// Get all tab nav links
	var tabLink = document.querySelectorAll(".tab-nav  a");
	// Loop through each tab nav link
	for (let index = 0; index < tabLink.length; index++) {
		tabLink[index].addEventListener("click", function () {
			const tabNav = this.parentNode.parentNode;
			const tabNavItems = tabNav.querySelectorAll("li");
			tabNavItems.forEach((item) => item.classList.remove("active"));
			this.parentNode.classList.add("active");

			const tab = this.getAttribute("data-type");
			const tabWrap = tabNav.parentNode.parentNode;
			const tabItems = tabWrap.querySelectorAll(".tab-item");
			tabItems.forEach((item) => item.classList.remove("active"));

			const activeTab = tabWrap.querySelector("#" + tab);
			if (activeTab) activeTab.classList.add("active");
		});
	}
	// Add click event listener to each link

	//Drop down menu
	if (window.innerWidth < 1200.1) {
		document
			.querySelectorAll(".drop-down .title em")
			.forEach(function (em) {
				em.addEventListener("click", function () {
					var dropDown = this.parentElement.parentElement;
					var navSub = dropDown.querySelector(".nav-sub");

					if (dropDown.classList.contains("is-open")) {
						document
							.querySelectorAll(".drop-down .nav-sub")
							.forEach(function (sub) {
								sub.style.display = "none";
							});
						document
							.querySelectorAll(".drop-down")
							.forEach(function (item) {
								item.classList.remove("is-open");
							});
					} else {
						document
							.querySelectorAll(".drop-down .nav-sub")
							.forEach(function (sub) {
								sub.style.display = "none";
							});
						document
							.querySelectorAll(".drop-down")
							.forEach(function (item) {
								item.classList.remove("is-open");
							});
						navSub.style.display = "block";
						dropDown.classList.add("is-open");
					}
				});
			});
	}
	//Toggle item
	document.querySelectorAll(".toggle-wrap").forEach((wrap, index) => {
		wrap.classList.add("toggle-instance-" + index);

		wrap.querySelectorAll(
			".toggle-instance-" + index + " .toggle-item"
		).forEach((item) => {
			item.addEventListener("click", () => {
				if (item.classList.contains("is-toggle")) {
					item.querySelector(".article").style.display = "none";
					item.classList.remove("is-toggle");
				} else {
					document
						.querySelectorAll(
							".toggle-instance-" + index + " .toggle-item"
						)
						.forEach((otherItem) => {
							otherItem.classList.remove("is-toggle");
							otherItem.querySelector(".article").style.display =
								"none";
						});
					item.querySelector(".article").style.display = "block";
					item.classList.add("is-toggle");
				}
			});
		});
	});

	//setBackground
	setBackground.forEach((attributes) => {
		attributes.style.cssText = `
		background-image: url(${attributes.getAttribute("setBackground")});
		background-size: cover;
		background-position: center;
	  `;
	});
	if (counterItem !== null) {
		if (counterItem.length) {
			const counterUp = window.counterUp.default;
			const callback = (entries) => {
				entries.forEach((entry) => {
					const el = entry.target;
					if (
						entry.isIntersecting &&
						!el.classList.contains("is-visible")
					) {
						counterUp(el, {
							duration: 2000,
							delay: 200,
						});
						el.classList.add("is-visible");
					}
				});
			};
			const IO = new IntersectionObserver(callback, { threshold: 1 });

			counterItem.forEach((counter) => {
				IO.observe(counter);
			});
		}
	}
}
