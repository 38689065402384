import { basicTask } from "./task";
import { swiperInit } from "./swiper";
const bodyWrap = document.querySelector("body");
const sideNav = document.querySelector(".sidenav-group");
const hamburger = document.querySelector(".hamburger");
const menuMobile = document.querySelector(".mobile-nav-wrap");
const searchWrap = document.querySelector(".search-overlay");

document.addEventListener("DOMContentLoaded", () => {
	mappingFunc();
	swiperInit();
	basicTask();
	generalFunction.init();
});

window.addEventListener("resize", function () {
	let width = this.window.innerWidth;
	if (width <= 1200.1) {
		mappingFunc();
		generalFunction.hideElement().init();
	}
});
window.addEventListener("scroll", function () {
	const width = this.window.innerWidth;
	const scrollTop = window.scrollY || document.documentElement.scrollTop;
	if (
		document.body.scrollTop > 100 ||
		document.documentElement.scrollTop > 100
	) {
		bodyWrap.classList.add("minimize");
	} else {
		bodyWrap.classList.remove("minimize");
	}
	if (scrollTop > window.innerHeight) {
		sideNav.classList.add("show");
	} else {
		sideNav.classList.remove("show");
	}
});
document.addEventListener("click", (event) => {
	if (!event.target.closest(".search-toggle, .search-overlay")) {
		searchWrap.classList.remove("is-open");
	}
	if (!event.target.closest(".mobile-nav-wrap, .site-menu-toggle")) {
		menuMobile.classList.remove("is-open");
		bodyWrap.classList.remove("overlay-bg");
		hamburger.classList.remove("is-active");
	}
});

$("body").on("click", function () {
	$(".wrap-form input[submit]").click(() => {
		const wrapForms = document.querySelectorAll(".wrap-form");
		if (wrapForms !== null) {
			wrapForms.forEach(function (form) {
				const btnWrap = form.querySelector(".frm-btn");

				const icon = document.createElement("div");
				icon.classList.add("icon");
				btnWrap.append(icon);
			});
		}
	});
});

Fancybox.bind("[data-fancybox]", {
	parentEl: document.body[0], // Element containing main structure
});

const generalFunction = {
	a11yUser: () => {
		const wrapForms = document.querySelectorAll(".wrap-form");
		if (wrapForms !== null) {
			wrapForms.forEach(function (form) {
				const btnWrap = form.querySelector(".frm-btn");
				const submitInput = form.querySelector("input[type='submit']");
				const labelFor = submitInput.getAttribute("name");
				const labelSubmit = form.querySelector(".frm-btnwrap .label");

				const icon = document.createElement("div");
				icon.classList.add("icon");
				btnWrap.append(icon);
				if (labelFor !== null) {
					if (labelSubmit !== null) {
						labelSubmit.textContent = "Submit Button";
						labelSubmit.setAttribute("for", labelFor);
					}
				}
			});
		}
	},
	readMore: () => {
		$(".read-more-wrap").each(function (index) {
			$(this).addClass("read-instance-" + index);
			let $this = $(".read-instance-" + index);
			$this.each(function () {
				let button = $this.find(".btn-read-more");
				let content = $this;
				button.on("click", function () {
					content.toggleClass("active");
				});
			});
		});
	},
	hideElement: () => {
		if ($(window).width() < 1200.1) {
			[".searchbox", ".nav-primary-menu"]
				.map((selector) => document.querySelector(selector))
				.forEach((el) => (el.style.display = "flex"));
		}
	},
	init: () => {
		generalFunction.a11yUser();
		generalFunction.hideElement();
		generalFunction.readMore();
	},
};

function mappingFunc() {
	new MappingListener({
		selector: ".header-contact-group",
		mobileWrapper: "main",
		mobileMethod: "appendTo",
		desktopWrapper: ".site-menu-toggle",
		desktopMethod: "insertBefore",
		breakpoint: 1200.1,
	}).watch();
	new MappingListener({
		selector: ".nav-primary-menu",
		mobileWrapper: ".mobile-nav-wrap",
		mobileMethod: "appendTo",
		desktopWrapper: ".language-wrap",
		desktopMethod: "insertBefore",
		breakpoint: 1200.1,
	}).watch();
	new MappingListener({
		selector: ".search-overlay",
		mobileWrapper: ".mobile-top-nav",
		mobileMethod: "appendTo",
		desktopWrapper: ".search-toggle",
		desktopMethod: "insertAfter",
		breakpoint: 1200.1,
	}).watch();
}
